import htmx from 'htmx.org'

import jsMeta from './js/meta.js'
import jsResizeAnimationStopper from './js/resizeAnimationStopper.js'
import jsHeader from './js/header.js'
import jsCookieBanner from './js/cookieBanner.js'
import jsLazy from './js/lazy.js'
import jsSlider from './js/slider.js'
import jsPlyr from './js/plyr.js'
import jsFilters from './js/filters.js'
import jsLightbox from './js/lightbox.js'
import jsToggle from './js/toggle.js'
import jsPopover from './js/popover.js'
import jsNavActives from './js/navActives.js'
import jsHoverImage from './js/hoverImage.js'
import jsNewsletter from './js/newsletter.js'
import jsHeadroom from './js/headroom.js'
import jsScrollTop from './js/scrolltop.js'
import jsScrollInteractions from './js/scrollinteractions.js'

jsResizeAnimationStopper.init();
jsPopover.init();
jsHeader.init();
jsCookieBanner.init();
jsLazy.init();
jsSlider.init();
jsPlyr.init();
// jsAccordion.init();
jsFilters.init();
jsLightbox.init();
jsToggle.init();
jsNavActives.all();
jsHoverImage.init();
jsNewsletter.init();
jsHeadroom.init();
jsScrollTop.init();
jsScrollInteractions.init();

jsLightbox.checkOpen();

/* jsHeader.actives.manage();
jsHeader.actives.detect(); */

htmx.on("htmx:beforeSend", function(evt) {
  console.log('hx target', evt.target.classList);
  if (!evt.target.classList.contains('js-htmx-loadmore')) {
    document.querySelector('.js-main').classList.add('is-loading');

    setTimeout(function() {
      jsHeader.closeNav();
    }, 200);
  } else {

    document.querySelectorAll('.js-htmx-loading').forEach(function(el) {
      el.classList.add('is-loading');
    });

    document.querySelectorAll('.js-htmx-loadmore').forEach(function(el) {
      el.classList.remove('is-active');
    });

    evt.target.classList.add('is-active');

  }
});

htmx.on("htmx:historyRestore", function(evt) {
  // Save the current scroll position
  const scrollPosition = window.scrollY;

  console.log("htmx:historyRestore");

  jsLightbox.closeAllInstances();

  swapEvents(evt);

  jsLazy.update();

  // Restore the scroll position after the update
  window.scrollTo(0, scrollPosition);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log("htmx:afterSwap", evt.target);

  if (evt.target.classList.contains('js-htmx-main')) {
    const htmlElement = document.documentElement;
    htmlElement.classList.remove('smooth');

    window.scrollTo(0, 0);

    setTimeout(function () {
      htmlElement.classList.add('smooth');
    }, 50);
  }

  swapEvents(evt);
});

htmx.on("htmx:responseError", function (error) {
  console.error(error);

  document.querySelector('.js-main').classList.remove('is-loading');

  document.querySelectorAll('.js-htmx-loading').forEach(function(el) {
    el.classList.remove('is-loading');
  });
})

function swapEvents(evt) {
  // refresh HTMX
  htmx.process(document.body);
  setTimeout(function() {
    jsMeta.update();
    jsLazy.reset();
    jsPlyr.reset();
    jsFilters.reset();
    jsLightbox.reset();
    jsToggle.reset();
    jsNavActives.resetOnScroll();
    jsHoverImage.reset();
    jsHeader.actives.detect();
    jsScrollInteractions.reset();


    console.log('evt', evt);

    if (!evt.target.classList.contains('sprig-component')) {
      jsSlider.reset();
    }

    if (evt.type == 'htmx:historyRestore' || evt.target.classList.contains('js-htmx-main')) {
      jsLightbox.checkOpen();
    }

    const vid = document.querySelectorAll('.js-video');

    if (vid.length > 0) {
      vid.forEach(function(el) {
        el.play();
      });
    }

    document.querySelector('.js-main').classList.remove('is-loading');

    document.querySelectorAll('.js-htmx-loading').forEach(function(el) {
      el.classList.remove('is-loading');
    });

  }, 200);
}
